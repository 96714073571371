<template>
  <section id="cart-section">
    <div class="container mt-5">
      <!-- <h1 class="h3 mb-5">Payment</h1> -->
      <div class="row">
        <!-- Left -->
        <div class="col-lg-9 mb-3">
          <div class="card">
            <div class="card-header">
              <h2>{{ $t("message.cart.cart") }}</h2>
            </div>
            <div class="card-body" >
              <div class="table-responsive">
                <table class="table table-bordered m-0">
                  <thead>
                    <tr>
                      <!-- Set columns width -->
                      <th
                        class="text-center py-3 px-4"
                        style="min-width: 400px"
                      >
                        Product Name &amp; Details
                      </th>
                      <th class="text-right py-3 px-4" style="width: 100px">
                        Price
                      </th>
                      <th class="text-center py-3 px-4" style="width: 120px">
                        Quantity
                      </th>
                      <th class="text-right py-3 px-4" style="width: 100px">
                        Total
                      </th>
                      <th
                        class="text-center align-middle py-3 px-0"
                        style="width: 40px"
                      >
                        <a
                          href="#"
                          class="shop-tooltip float-none text-light"
                          title=""
                          data-original-title="Clear cart"
                          ><i class="ino ion-md-trash"></i
                        ></a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cart,key) in carts" :key="key">
                      <td class="p-4" >
                        <div class="media align-items-center"  >
                          <img
                            :src="imgShow(cart.product.thumbnail_img)" alt=""
                            class="d-block ui-w-40 ui-bordered mr-4"
                            
                          />
                          <div class="media-body" >
                            <a href="#" class="d-block text-dark">{{ cart.product.name }}</a>
                            <small>
                              <span class="text-muted">Color:</span>
                              <span
                                class="
                                  ui-product-color ui-product-color-sm
                                  align-text-bottom
                                "
                                style="background: #e81e2c"
                              ></span>
                              &nbsp; <span class="text-muted">Size: </span> EU
                              37 &nbsp;
                              <span class="text-muted">Ships from: </span> China
                            </small>
                          </div>
                        </div>
                      </td>
                      <td
                        class="text-right font-weight-semibold align-middle p-4"
                      >
                        {{cart.price}}
                      </td>
                      <td class="align-middle p-4">
                        <input
                          type="number"
                          @change="qtyChange(key,cart.product,cart.available_product_qty)"
                          name="quantity"
                          v-model.number="quantity=cart.quantity"
                          class="form-control input-number"
                        
                        />
                        
                      </td>
                      <td
                        class="text-right font-weight-semibold align-middle p-4"
                      >
                       {{ getSubTotal + totalTax + totalShippingCost }}
                      </td>
                      <td class="text-center align-middle px-0">
                        <a
                          href="javascript:void(0)"
                          @click="removeProduct(index)"
                          type="button"
                          class="shop-tooltip close float-none text-danger"
                          title=""
                          data-original-title="Remove"
                          >×</a
                        >
                      </td>
                    </tr>

                    <!-- <tr>
                      <td class="p-4">
                        <div class="media align-items-center">
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar6.png"
                            class="d-block ui-w-40 ui-bordered mr-4"
                            alt=""
                          />
                          <div class="media-body">
                            <a href="#" class="d-block text-dark">Product 2</a>
                            <small>
                              <span class="text-muted">Color:</span>
                              <span
                                class="
                                  ui-product-color ui-product-color-sm
                                  align-text-bottom
                                "
                                style="background: #000"
                              ></span>
                              &nbsp;
                              <span class="text-muted">Storage: </span> 32GB
                              &nbsp;
                              <span class="text-muted">Warranty: </span>
                              Standard - 1 year &nbsp;
                              <span class="text-muted">Ships from: </span> China
                            </small>
                          </div>
                        </div>
                      </td>
                      <td
                        class="text-right font-weight-semibold align-middle p-4"
                      >
                        $1049.00
                      </td>
                      <td class="align-middle p-4">
                        <input
                          type="text"
                          class="form-control text-center"
                          value="1"
                        />
                      </td>
                      <td
                        class="text-right font-weight-semibold align-middle p-4"
                      >
                        $1049.00
                      </td>
                      <td class="text-center align-middle px-0">
                        <a
                          href="#"
                          class="shop-tooltip close float-none text-danger"
                          title=""
                          data-original-title="Remove"
                          >×</a
                        >
                      </td>
                    </tr>

                    <tr>
                      <td class="p-4">
                        <div class="media align-items-center">
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar2.png"
                            class="d-block ui-w-40 ui-bordered mr-4"
                            alt=""
                          />
                          <div class="media-body">
                            <a href="#" class="d-block text-dark">Product 3</a>
                            <small>
                              <span class="text-muted">Ships from: </span>
                              Germany
                            </small>
                          </div>
                        </div>
                      </td>
                      <td
                        class="text-right font-weight-semibold align-middle p-4"
                      >
                        $20.55
                      </td>
                      <td class="align-middle p-4">
                        <input
                          type="text"
                          class="form-control text-center"
                          value="1"
                        />
                      </td>
                      <td
                        class="text-right font-weight-semibold align-middle p-4"
                      >
                        $20.55
                      </td>
                      <td class="text-center align-middle px-0">
                        <a
                          href="#"
                          class="shop-tooltip close float-none text-danger"
                          title=""
                          data-original-title="Remove"
                          >×</a
                        >
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Right -->
        <div class="col-lg-3">
          <div class="card position-sticky top-0">
            <div class="p-3 bg-light bg-opacity-10">
              <h6 class="card-title mb-3">Order Summary</h6>
              <div class="d-flex justify-content-between mb-1 small">
                <span>Subtotal</span> <span>{{ getSubTotal }}</span>
              </div>
              <div class="d-flex justify-content-between mb-1 small">
                <span>Shipping</span> <span>{{ totalShippingCost }}</span>
              </div>
              <div class="d-flex justify-content-between mb-1 small">
                <span>Coupon (Code: NEWYEAR)</span>
                <span class="text-danger">-$10.00</span>
              </div>
              <hr />
              <div class="d-flex justify-content-between mb-4 small">
                <span>TOTAL</span> <strong class="text-dark">{{ getSubTotal + totalTax + totalShippingCost }}</strong>
              </div>
              <div class="form-check mb-1 small">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="tnc"
                />
                <label class="form-check-label" for="tnc">
                  I agree to the <a href="#">terms and conditions</a>
                </label>
              </div>
              <div class="form-check mb-3 small">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="subscribe"
                />
                <label class="form-check-label" for="subscribe">
                  Get emails about product updates and events. If you change
                  your mind, you can unsubscribe at any time.
                  <a href="#">Privacy Policy</a>
                </label>
              </div>
              <button type="button" class="btn btn-primary w-100 mt-2">
                 <router-link :to="{name:'cart.checkout'}" style="color:white;">Continue to checkout</router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import {
  REMOVE_FORM_CART,
  UPDATE_CART,
} from "../../core/services/store/module/cart";

export default {
  name: "Cart2",
  data() {
    return {
      quantity: 1,
    };
  },
  created() {
    //this.$store.dispatch(ABOUT_US)
  },
  computed: {
    ...mapGetters([
      "carts",
      "getTotal",
      "getSubTotal",
      "totalShippingCost",
      "totalTax",
    ]),
  },
  methods: {
    imgShow(path) {
      return process.env.VUE_APP_API_BASE_URL+'public/' + path;
    },
    /*
     * remove product from cart
     * */
    removeProduct(index) {
      this.$store.commit(REMOVE_FORM_CART, index);
    },
    /*
     * method for quantity change
     * type =0 minus
     * type =1 plus
     * */
    quantityChange(type, index, product, available_product_qty) {
      /*
       * if order quantity limit is enable
       * */
      if (
        product.orderQtyLimit &&
        (this.quantity <= product.orderQtyLimitMin ||
          this.quantity >= product.orderQtyLimitMax)
      ) {
        if (type == 0 && this.quantity - 1 < product.orderQtyLimitMin) {
          this.quantity = parseInt(product.orderQtyLimitMin);
          return;
        } else if (type == 1 && this.quantity + 1 > product.orderQtyLimitMax) {
          this.quantity = parseInt(product.orderQtyLimitMax);
          return;
        }
      }
      if (type == 0 && this.quantity > 1) {
        this.quantity -= 1;
      } else if (type == 1) {
        if (
          product.stockManagement == 1 &&
          (product.priceType == 0 || product.priceType == 2)
        ) {
          if (this.product.quantity > this.quantity) this.quantity += 1;
        } else if (product.stockManagement == 1 && product.priceType == 1) {
          if (available_product_qty > this.quantity) this.quantity += 1;
        } else this.quantity += 1;
      }
      this.$store.commit(UPDATE_CART, [index, this.quantity]);
    },
    /*
     * method for on change product qty check
     * */
    qtyChange(index, product, available_product_qty) {
      /*
       * if order quantity limit is enable
       * */
      if (
        product.orderQtyLimit &&
        (this.quantity < product.orderQtyLimitMin ||
          this.quantity > product.orderQtyLimitMax)
      ) {
        if (this.quantity < product.orderQtyLimitMin) {
          this.quantity = parseInt(product.orderQtyLimitMin);
          //return;
        } else if (this.quantity > product.orderQtyLimitMax) {
          this.quantity = parseInt(product.orderQtyLimitMax);
          //return;
        }
      }
      if (
        product.stockManagement == 1 &&
        (product.priceType == 0 || product.priceType == 2)
      ) {
        if (product.quantity < this.quantity)
          this.quantity = parseInt(product.quantity);
      } else if (product.stockManagement == 1 && product.priceType == 1) {
        if (available_product_qty < this.quantity)
          this.quantity = parseInt(available_product_qty);
      }
      this.$store.commit(UPDATE_CART, [index, this.quantity]);
    },
  },
};
</script>

<style scoped>
.ui-w-40 {
  width: 40px !important;
  height: auto;
}

.card {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}

.ui-product-color {
  display: inline-block;
  overflow: hidden;
  margin: 0.144em;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 10rem;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  vertical-align: middle;
}

body {
  background: #eee;
}

.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

#cart-section table .media {
  display: flex;
  gap: 20px;
}
#cart-section table img {
  width: 150px;
}

.media.align-items-center img {
  width: 150px;
}

table.table.table-bordered.m-0 tr td:first-child img {
  width: 71px;
}

.media.align-items-center {
  display: flex;
  gap: 20px;
}

table.table.table-bordered tbody {
  border-top: 0px !important;
}
</style>